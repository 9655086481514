import { Col, Container, Row } from "react-bootstrap";
import logo from "./../assets/img/logo-white.png";
import { FaInstagram } from "react-icons/fa";

const MainFooter = () => {

    return (
        <div className="footer">
            <Container className="py-3">
                <Row className="align-items-center">
                    <Col xs={10}>
                        <Row>
                            <Col xs={12} md={'auto'}>
                                <div className='text-white'><strong>© {new Date().getFullYear()} TEMA</strong></div>
                            </Col>
                            <Col xs={12} md>
                                <a href="/ketentuan-layanan">
                                    Ketentuan Layanan
                                </a>
                                <a href="/kebijakan-privasi" className="ms-3">
                                    Kebijakan Privasi
                                </a>
                                <a href="/form-talent" className="ms-3">
                                    Lowongan Kerja
                                </a>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={2}>
                        <div className="text-right">
                            <a href="" target="_blank">
                                <FaInstagram size={25} color=""/>
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default MainFooter;
