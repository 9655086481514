import { useEffect, useState } from "react";
import { Badge, Card, CardBody, Col, Container, Row, Spinner } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ImgTalentDefault from "../assets/img/talent-default.png"
import ButtonLoading from "../components/ButtonLoading";
import { Parallax } from 'react-scroll-parallax';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import http from "../components/utils/Request";
 
const animatedComponents = makeAnimated();

const Talent = () => {
    const [city, setCity] = useState([])
    const [citySelected, setCitySelected] = useState<any>([])
    const [talent, setTalent] = useState<any>([])
    const [talentLoading, setTalentLoading] = useState(false)
    const [talentTotalPage, setTalentTotalPage] = useState(1)
    const [talentPage, setTalentPage] = useState(1)
    const [initLoading, setInitLoading] = useState(false)

    const loadCity = () =>  {
        http.get(`public/domicile`, {
            params: {
                order: [
                    {
                        field: 'domicile',
                        sort: 'asc'
                    }
                ]
            }
        })
        .then((res) => {
            setCity(res.data.data.map((item:any) => ({ value: item.domicile, label: item.domicile })))
        })
    }

    const loadTalent = (page:number) => {
        setTalentLoading(true)
        if(page === 1){
            setInitLoading(true)
        }

        http.get(`public/talent`, {
            params: {
                page: page,
                rows: 24,
                where: citySelected.map((item:any) => item.value),
                order: [
                    {
                        field: 'talent_id',
                        sort: 'asc'
                    }
                ]
            }
        }).then((res) => {
            if(page === 1){
                setTalent(res.data.data.data)
            }else{
                setTalent([...talent, ...res.data.data.data])
            }
            setTalentTotalPage(res.data.data.lastPage)
            setTalentPage(res.data.data.page)
        }).finally(() => {
            setTalentLoading(false)
            setInitLoading(false)
        })
    }

    useEffect(() => {
        loadCity()
    }, [])

    useEffect(() => {
        loadTalent(1)
    }, [citySelected])

    return(
        <div className="pt-5">
            <Container id="home">
                <div className="talent-content">
                    <Parallax speed={-20} className="main-parallax-3"/>
                    <Parallax speed={30} className="main-parallax-2"/>
                    <Parallax speed={-15} className="main-parallax-4"/>
                    <div className="content-wrapper fade-in-text">
                        <div className="subtitle">TALENT KAMI</div>
                        <div className="title">Layanan Tim di Lapangan Akan Membantu Bisnis Anda Menembus Pasar.</div>
                        <div className="desc">Kami menyediakan seluruh ekosistem untuk ruang kerja dengan berbagai persyaratan dan kebutuhan.</div>
                    </div>
                </div>
            </Container>
            <div className="talent-list-wrapper pt-2 pb-5">
                <Container>
                    <div className="mt-5">
                        <Row className="align-items-center">
                            <Col xs={'auto'}>
                                <strong>Filter Lokasi</strong>
                            </Col>
                            <Col>
                                <Select
                                    placeholder="Pilih Lokasi" 
                                    options={city} 
                                    isMulti
                                    components={animatedComponents}
                                    onChange={(selected) => setCitySelected(selected)}
                                />
                            </Col>
                        </Row>
                    </div>

                    <div className="mt-5">
                        {
                            initLoading &&
                            <div className="text-center">
                                <Spinner animation="border" />
                                <div>Loading</div>
                            </div>
                        }
                        <Row>
                            {
                                talent.map((item:any, index:number) => (
                                    <Col key={`talent-${index}`} xs={12} sm={6} md={4} className="mb-3">
                                        <PhotoProvider maskOpacity={.7}>
                                            <PhotoView src={item.photogrid ? item.photogrid : ImgTalentDefault}>
                                                <Card className="talent-list pointer hover-zoom no-border shadow" style={{height:"100%"}}>
                                                    <CardBody>
                                                        <div className="d-flex flex-row gap-3">
                                                            <img src={item.photogrid ? item.photogrid : ImgTalentDefault} alt=""/>
                                                            <div className="flex-1">
                                                                <strong className="text-wrap">{item.name.replace(/(?<!^).(?!$)/g, '*')}</strong>
                                                                <div className="text-brand">{item.domicile}</div>
                                                                <div className="mt-2 d-flex flex-row gap-2 align-items-center">
                                                                    <div className="flex-1">
                                                                        <div className="header">{item.old}</div>
                                                                        <div>Tahun</div>
                                                                    </div>
                                                                    <div className="flex-1">
                                                                        <div>{item.height} cm</div>
                                                                        <div>{item.weight} kg</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="text-inactive mt-2" style={{ fontSize:12 }}>Keahlian:</div>
                                                        {
                                                            item.skill ? (
                                                                <div className="mt-1 d-flex flex-row flex-wrap gap-1">
                                                                    {
                                                                        item.skill?.split(",")?.map((exp:any, expIdx:number) => (
                                                                            <h6 key={`exp-${expIdx}`} style={{ marginBottom:0 }}><Badge bg="brand">{exp}</Badge></h6>
                                                                        ))
                                                                    }
                                                                </div>
                                                            ):(
                                                                <div className="mt-1 d-flex flex-row flex-wrap gap-1">
                                                                    {
                                                                        item.experience?.split(",")?.map((exp:any, expIdx:number) => (
                                                                            <h6 key={`exp-${expIdx}`} style={{ marginBottom:0 }}><Badge bg="brand">{exp}</Badge></h6>
                                                                        ))
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    </CardBody>
                                                </Card>
                                            </PhotoView>
                                        </PhotoProvider>
                                    </Col>
                                ))
                            }
                        </Row>

                        {
                            talentTotalPage > talentPage &&
                            <div className="text-center mt-3">
                                <ButtonLoading variant="outline-brand" loading={talentLoading.toString()} onClick={() => loadTalent(talentPage+1)}>Muat Lebih Banyak</ButtonLoading>
                            </div>
                        }
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Talent