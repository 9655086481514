import { Button, Col, Container, Row } from "react-bootstrap";
import { Parallax } from 'react-scroll-parallax';
import ScrollAnimation from "react-animate-on-scroll";
import { MdOutlineBusinessCenter, MdOutlineHandshake, MdOutlineEventNote } from "react-icons/md";
import Service from "../components/Service";
import Product from "../components/Product";
import Partner from "../components/Partner";
import CallUs from "../components/CallUs";
import OurTalent from "../components/OurTalent";
import ForTalent from "../components/ForTalent";

const Home = () => {

    const scrollTo = (id:string) => {
        const element = document.getElementById(id);
        const headerOffset = 70;
        if (element) {
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
           });
        }
    }

    return(
        <div>
            <Container id="home">
                <div className="main-content">
                    <Parallax speed={-20} className="main-parallax-3"/>
                    <Parallax speed={30} className="main-parallax-2"/>
                    <Parallax speed={-15} className="main-parallax-1"/>
                    <div className="content-wrapper fade-in-text">
                        <div className="subtitle">Jelajahi TEMA Hari Ini</div>
                        <div className="title">Penuhi Beragam Kebutuhan Bisnis, Jasa & Acara</div>
                        <div className="desc">SPG, merchandiser, usher, dan team leader. Jasa yang ditawarkan dapat diakses melalui situs dan aplikasi</div>

                        <Row className="mt-4">
                            <Col md={'auto'} className="mb-2">
                                <Button variant="brand" onClick={() => scrollTo("principal")}><strong>Principal</strong></Button>
                            </Col>
                            <Col md={'auto'} className="mb-2">
                                <Button variant="outline-brand" onClick={() => scrollTo("for-talent")}><strong>Sebagai Talent</strong></Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
            <div className="about-us bg-brand-opacity pt-5 pb-5" id="about-us">
                <Container className="pt-5 pb-5">
                    <Row>
                        <Col sm={12} md={5} lg={5}>
                            <div className="about-us-image-wrapper">
                                <Parallax speed={0} className="about-us-parallax-1"/>
                                <Parallax speed={-10} className="about-us-parallax-3"/>
                                <Parallax speed={30} className="about-us-parallax-2"/>
                            </div>
                        </Col>
                        <Col sm={12} md={7} lg={7}>
                            <ScrollAnimation animateIn='bounce' animateOnce={true}>
                                <strong className="text-brand">TENTANG KAMI</strong>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                                <div className="header mt-4 text-black">Memberikan Kualitas Untuk Mendukung<br/>Peningkatan & Bisnis Anda</div>
                                <p className="mt-4">TEMA menawarkan jasa penyediaan talent outsourcing (manusia) untuk memenuhi beragam kebutuhan bisnis, jasa, dan acara. Contohnya SPG, merchandiser, usher, dan team leader. Jasa yang ditawarkan dapat diakses melalui situs dan aplikasi (teknologi)</p>
                                <p>Kami menyediakan seluruh ekosistem untuk tenaga kerja dengan berbagai persyaratan dan kebutuhan. Layanan tim di lapangan akan membantu bisnis Anda menembus pasar.</p>
                            </ScrollAnimation>

                            <div className="mt-3">
                                <div className="service-list">
                                    <ScrollAnimation animateIn='fadeInRight' delay={0} animateOnce={true}>
                                        <div className="list">
                                            <MdOutlineBusinessCenter/>
                                            <div className="desc">Bisnis</div>
                                        </div>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn='fadeInRight' delay={100} animateOnce={true}>
                                        <div className="list">
                                            <MdOutlineHandshake/>
                                            <div className="desc">Jasa</div>
                                        </div>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn='fadeInRight' delay={200} animateOnce={true}>
                                        <div className="list">
                                            <MdOutlineEventNote/>
                                            <div className="desc">Acara</div>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div id="principal">
                <Service/>
            </div>

            <Product/>

            <Partner/>

            {/* <OurTalent/> */}

            <CallUs/>
            
            <div id="for-talent">
                <ForTalent/>
            </div>
        </div>
    )
}

export default Home