import { Card, Col, Container, Form, Row } from "react-bootstrap"
import { IoCallSharp, IoLocationSharp, IoMailSharp } from "react-icons/io5"
import ScrollAnimation from "react-animate-on-scroll"
import { useState } from "react"
import ButtonLoading from "./ButtonLoading"
import http from "./utils/Request"
import SweetAlert from "./SweetAlert"
import { useNavigate } from "react-router-dom"

const CallUs = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
        fullname: '',
        email: '',
        company: '',
        message: ''
    })

    const onSubmit = (e:any) => {
        e.preventDefault()
        
        var mailformat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if(mailformat.test(form.email) === false){
            SweetAlert({
                icon: 'error',
                text: "Email tidak valid"
            })
            return false
        }

        setLoading(true)
        http.post(`public/send-message`, {
            fullname: form.fullname,
            company: form.company,
            email: form.email,
            message: form.message
        }).then((res) => {
            if (res.data.status) {
                navigate("/terima-kasih", { state: { key: "call-us" } })
            } else {
                SweetAlert({
                    icon: 'error',
                    text: res.data.message
                })
            }
        }).catch((error) => {
            if (error.response) {
                SweetAlert({
                    icon: 'error',
                    text: error.response.data.error
                })
            } else {
                SweetAlert({
                    icon: 'error',
                    text: 'Oops.. Terjadi Kesalahan!'
                })
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    return(
        <div className="pt-5 pb-5" id="call-us">
            <div className="bg-call-us"/>
            <Container>
                <Row>
                    <Col sm={12} md={5}>
                        <div className="company-detail">
                            <Card>
                                <Card.Body className="p-5">
                                    <div className="header text-black">Bagaimana kami bisa membantu Anda?</div> 
                                    <p className="mt-3">Silakan gunakan formulir ini untuk menghubungi kami dan kami akan segera menghubungi Anda. Kami menyediakan seluruh ekosistem untuk ruang kerja dengan berbagai persyaratan dan kebutuhan. Layanan tim di lapangan akan membantu bisnis Anda menembus pasar.</p>

                                    <div className="mt-4">
                                        {/* <div className="d-flex flex-row align-items-center gap-3">
                                            <IoLocationSharp size={30}/>
                                            <div className="flex-filll">
                                                Centennial Tower, 38’th floor, Unit H
                                                <br/>Jl. Gatot Subroto, kavling 24-25, Jakarta Selatan, 12930, Indonesia
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row align-items-center gap-3 mt-4">
                                            <IoCallSharp size={20}/>
                                            <div className="flex-filll">021000001</div>
                                        </div> */}
                                        <div className="d-flex flex-row align-items-center gap-3">
                                            <IoMailSharp size={20}/>
                                            <div className="flex-filll">tema@indinesia.id</div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                    <Col sm={12} md={7}>
                        <div className="pt-4">
                            <ScrollAnimation animateIn='bounce' animateOnce={true}>
                                <strong className="text-brand text-center-mobile">HUBUNGI KAMI</strong>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                                <Form className="mt-4" onSubmit={onSubmit}>
                                    <Form.Group className="mb-3">
                                        <Form.Label><strong className="text-black">Nama Lengkap</strong></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Masukan Nama Lengkap"
                                            value={form.fullname}
                                            onChange={(event) => setForm(state => ({...state, fullname: event.target.value}))}
                                            disabled={loading}
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label><strong className="text-black">Perusahaan</strong></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Masukan Perusahaan"
                                            value={form.company}
                                            onChange={(event) => setForm(state => ({...state, company: event.target.value}))}
                                            disabled={loading}
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label><strong className="text-black">Email</strong></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Masukan Email"
                                            value={form.email}
                                            onChange={(event) => setForm(state => ({...state, email: event.target.value}))}
                                            disabled={loading}
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label><strong className="text-black">Pesan</strong></Form.Label>
                                        <Form.Control
                                            type="text"
                                            as="textarea"
                                            placeholder="Masukan Pesan"
                                            value={form.message}
                                            onChange={(event) => setForm(state => ({...state, message: event.target.value}))}
                                            disabled={loading}
                                            rows={5}
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group className="mt-4">
                                        <ButtonLoading type="submit" variant="brand" loading={loading.toString()}><strong>Kirim</strong></ButtonLoading>
                                    </Form.Group>
                                </Form>
                            </ScrollAnimation>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CallUs