import { Col, Container, Row } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll"
import Slider from "react-slick"

const settings = {
    dots: true,
    autoplay: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    speed: 1000,
    arrows: false,
    rows: 2,
    slidesPerRow: 6
  }

const Partner = () => {
    return(
        <Container className="mt-5 mb-5 pt-5 pb-5">
            <ScrollAnimation animateIn='bounce' animateOnce={true} className="text-center">
                <strong className="text-brand">PARTNER</strong>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                <div className="header mt-4 text-black text-center">Partner Kami Yang Berharga</div>
            </ScrollAnimation>

            <Slider {...settings} className="mt-5">
                {[...Array(32)].map((x, i) => (
                    <div key={`partner-${i}`} className="mb-3">
                        <div className="partner-logo-wrapper">
                            <img src={require(`../assets/img/partner/${i+1}.png`)} className="hover-zoom" alt="" />
                        </div>
                    </div>
                ))}
            </Slider>
        </Container>
    )
}

export default Partner